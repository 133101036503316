import './App.css';
import React, { useState , useEffect} from "react";

function App() {

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () =>{
      const resp = await fetch(
        "https://ga.henrylhtsang.workers.dev/posts",
        {method: 'GET'}
      );
      const postsResp = await resp.json();
      setPosts(postsResp);
    }
    getPosts();
  }, []);

  return (
    <div>
      <h1>Here are the posts!</h1>
        {posts.map(post => (
          <div>
            <h2>
              Title: {post.title}
            </h2>
            <h3>
              Username: {post.username}
            </h3>
            <p>
              Content: {post.content}
            </p>
          </div>
        ))}
    </div>
  );
}



export default App;
